import { ref } from '@vue/composition-api'

const defaultState = () => ({
  bookmarks: ref([]),
  colors: ref([]),
  permsColors: ref({}),
  moduleView: ref(''),
  messageAlertForm: ref(''),
  form_data: ref([]),
  requestError: ref(''),
})

export default defaultState
