<template>
  <div style="height: inherit">

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list position-relative space-bottom">

      <!-- Barra pesquisa / ordenação -->
      <div class="app-fixed-search d-flex align-items-center">
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            :size="sizeIconsAction"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxt"
              value=""
              :placeholder="listview_txt_default.txt_search"
            />
          </b-input-group>
        </div>

        <div
          v-if="fieldsOrder.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrder"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderBy(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBy(oField.name)"
                :class="listviewIconOrderBy(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </div>

      <!-- Listagem -->
      <div class="email-user-list scroll-area">
        <vue-pull-refresh
          ref="listviewScroll"
          :on-refresh="listViewRefresh"
          :config="{
            errorLabel: $t('Problema ao atualizar'),
            startLabel: $t('Baixar para atualizar'),
            readyLabel: $t('Solte para atualizar'),
            loadingLabel: $t('A atualizar...'),
            pullDownHeight: 60,
          }"
        >
          <listview-list-data
            v-if="listviewData.length > 0"
            ref="list"
            :open-modal-detail-by-row="openModalDetailByRow"
          />
          <div
            class="no-results"
            :class="{'show': !listviewData.length}"
          >
            <h5 v-if="(filterSelectedTotalFields === 0) && !listviewData.length && (listviewInit === 0)">
              {{ listview_txt_default.txt }}
            </h5>
            <h5 v-if="(filterSelectedTotalFields > 0) && !listviewData.length && (listviewInit === 0)">
              {{ listview_txt_default.txt_not_find }}
            </h5>
            <h5 v-if="listviewInit === 1">
              {{ listview_txt_default.txt_loading }}
            </h5>
          </div>
        </vue-pull-refresh>
      </div>

      <b-container
        v-if="listviewData.length > 0"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="mb-0 pagination-white pt-0_2 pb-0_2"
              :size="sizePaginationListviewResponsive"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-overlay
        :show="$store.getters['sales_processos/loadingListview']"
        no-wrap
      />

    </div>

    <portal to="content-renderer-sidebar-left">
      <listview-left-sidebar
        ref="leftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        :show-msg-error-request="showMsgErrorRequest"
        :form-gest-bookmarks="formGestBookmarks"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <bookmarks-modal-edition
      v-if="showBookmarksModalEdition===true"
      ref="bookmarksModalEdition"
    />

    <processo-modal-detail
      v-if="showProcessoModalDetail===true"
      ref="processoModalDetail"
      :row="rowModalAction"
    />

  </div>

</template>

<script>
import store from '@/store'
import '@/libs/sweet-alerts'
import { onUnmounted, computed } from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, VBTooltip, BDropdown, BDropdownItem, BRow, BCol, BContainer, BPagination, BOverlay,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import salesModule from '@store-modules/sales/listview'
import modelSW001 from '@store-modules/users/data'
import { sizeIconsAction, showMsgRequest } from '@core-custom/mixins/geral'
import bookmarksModalEdition from '@/views/modules/sales/bookmarks/modalEdition.vue'
import VuePullRefresh from 'vue-pull-refresh'
import { useRouter } from '@core/utils/utils'
import processoModalDetail from './modalDetail.vue'
import ListviewLeftSidebar from './leftSidebar.vue'
import ListviewListData from './listData.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BContainer,
    BPagination,
    BOverlay,
    ListviewLeftSidebar,
    ListviewListData,
    bookmarksModalEdition,
    processoModalDetail,
    VuePullRefresh,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction, showMsgRequest],
  data() {
    return {
      showBookmarksModalEdition: false,
      showProcessoModalDetail: false,
      rowModalAction: {},
    }
  },
  computed: {
    ...mapGetters('sales_processos', ['listviewData', 'filterData', 'filterSelected', 'listviewOrderBy', 'totalRows', 'perPage', 'listview_txt_default', 'listviewInit', 'fieldsOrder', 'filterSelectedTotalFields', 'positionListview']),
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
    currentPage: {
      get() { return this.$store.getters['sales_processos/currentPage'] },
      set(newValue) {
        this.$store.dispatch('sales_processos/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    searchTxt: {
      get() { return this.$store.getters['sales_processos/searchTxt'] },
      set(newValue) {
        this.$store.dispatch('sales_processos/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
  },
  watch: {
    $route() {
      this.$refs.leftSidebar.$refs.filterScroll.$el.scrollTop = 0
    },
    '$route.query': {
      immediate: false,
      handler(newVal) {
        this.loadFilterCustomQuery(newVal, true)
      },
    },
  },
  beforeDestroy() {
    this.$store.commit('sales_processos/savePositionListview', this.sPostionListview)
    this.$store.commit('sales_processos/saveStates')
  },
  async created() {
    try {
      this.$store.commit('sales_processos/setHistoryKeyStorage', this.$route.name)

      await store.dispatch('sales_processos/getDataFiltersListview', { pageSel: this.$route.name }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      const resultFilterCustomQuery = await this.loadFilterCustomQuery(this.routeParamsQuery, false)

      await store.dispatch('sales_processos/fetchListView', { skipCurrentPage: true }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.$nextTick()

      if ((this.positionListview !== null) && (this.positionListview !== undefined)) {
        const { scrollTop, scrollLeft } = this.positionListview
        this.$refs.listviewScroll.$el.scrollTop = scrollTop
        this.$refs.listviewScroll.$el.scrollLeft = scrollLeft
      }

      if (resultFilterCustomQuery.sViewDetailID !== '') {
        await this.openModalDetailByRowRequestInfo(resultFilterCustomQuery.sViewDetailID)
      }
    } catch (err) {
      //
    }
  },
  methods: {
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderBy.column === filterField) {
        if (this.listviewOrderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    formGestBookmarks() {
      this.showBookmarksModalEdition = true
    },
    listviewFilterOrderBy(field) {
      this.$store.dispatch('sales_processos/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    openModalDetailByRow(row) {
      this.showProcessoModalDetail = true
      this.rowModalAction = row || {}
    },
    listViewRefresh() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('sales_processos/pullToRefreshListview')
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    async openModalDetailByRowRequestInfo(idView) {
      if (idView !== '') {
        this.$store.dispatch('sales_processos/getInfoProcess', { sw152s02: idView }).then(row => {
          this.showProcessoModalDetail = true
          this.rowModalAction = row || {}
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      }
    },
    async loadFilterCustomQuery(paramsQuery, openViewDetail) {
      return new Promise(resolve => {
        let filterCustomQuery = false
        let sViewDetailID = ''
        if ((paramsQuery !== null) && (paramsQuery !== undefined)) {
          if (('viewDetail' in paramsQuery) && (paramsQuery.viewDetail !== '')) {
            filterCustomQuery = true
            sViewDetailID = paramsQuery.viewDetail
          }
        }

        if (filterCustomQuery === false) {
          this.$store.commit('sales_processos/initStates')
        } else {
          this.$router.replace({ query: {} })
        }

        if ((openViewDetail === true) && (sViewDetailID !== '')) {
          this.openModalDetailByRowRequestInfo(sViewDetailID)
        }

        resolve({ filterCustomQuery, sViewDetailID })
      })
    },
  },
  setup() {
    const SALES_MODULE_NAME = 'sales_processos'
    const MODEL_SW001 = 'sw001'

    if (!store.hasModule(SALES_MODULE_NAME)) {
      store.registerModule(SALES_MODULE_NAME, salesModule)

      onUnmounted(() => {
        if (store.hasModule(SALES_MODULE_NAME)) store.unregisterModule(SALES_MODULE_NAME)
      })
    }

    if (!store.hasModule(MODEL_SW001)) {
      store.registerModule(MODEL_SW001, modelSW001)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW001)) store.unregisterModule(MODEL_SW001)
      })
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const { route } = useRouter()
    const routeParamsQuery = computed(() => route.value.query)

    return {
      mqShallShowLeftSidebar,
      routeParamsQuery,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
/* Desativa as animação devido que provoca erro no scroll to refresh */
.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu.sws-not-button-create .sidebar-menu-list{
  height: calc(100%);
}

.pull-down-container ::v-deep{
  .pull-down-header{
    background-color:inherit!important;
  }
  .pull-down-content{
    font-family:inherit!important;
    color:inherit!important;
    font-size:inherit!important;
  }
}
</style>
