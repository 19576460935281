import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import btoa from 'btoa'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    bookmarks: state => state.bookmarks.value,
    colors: state => state.colors.value,
    permsColors: state => state.permsColors.value,
    messageAlertForm: state => state.messageAlertForm.value,
    form_data: state => state.form_data.value,
    requestError: state => state.requestError.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setBookmarks(state, payload) {
      state.bookmarks.value = []
      const valuesPayload = Object.values(payload)
      if (Array.isArray(valuesPayload)) {
        valuesPayload.forEach(bookmark => {
          state.bookmarks.value.push(bookmark)
        })
      }
    },
    setColors(state, payload) {
      state.colors.value = []

      if (Array.isArray(payload)) {
        payload.forEach(color => {
          state.colors.value.push(color)
        })
      }
    },
    setPermsColors(state, payload) {
      state.permsColors.value = payload || {}
    },
    setMessageAlertForm(state, payload) {
      state.messageAlertForm.value = payload
    },
    setFormDataBookmarks(state) {
      state.form_data.value = []
      if (Array.isArray(state.bookmarks.value)) {
        state.bookmarks.value.forEach(bookmark => {
          const newBookmark = bookmark
          state.form_data.value.push(newBookmark)
        })
      }
    },
    setUpdateFormData(state, payload) {
      const newData = Array.from(payload)
      state.form_data.value = newData
    },
    createNewBookmark(state) {
      const sTempItem = `new_${new Date().getTime()}_${state.form_data.value.length}`
      const newBookmark = {
        sw075s01: sTempItem,
        sw075s03: '',
        sw075s04: '',
        sw075s05: '',
        new: true,
      }

      state.form_data.value.push(newBookmark)
    },
    removeBookmark(state, payload) {
      if (state.form_data.value !== undefined) {
        if (payload in state.form_data.value) {
          state.form_data.value.splice(payload, 1)
        }
      }
    },
    setRequestError(state, payload) {
      state.requestError.value = payload
    },
  },
  actions: {
    // Obter os marcadores para editar
    async getAllBookmarksEdition({ commit }) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}salesprocess/formGestBookmarks`)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                commit('setRequestError', false)
                commit('setBookmarks', response.data.data.bookmarks)
                commit('setColors', response.data.data.colors)
                commit('setPermsColors', response.data.data.colors)
                commit('setMessageAlertForm', '')

                if (response.data.data.msgAlertUser !== undefined) {
                  commit('setMessageAlertForm', response.data.data.msgAlertUser)
                }

                commit('setFormDataBookmarks')
                resolve(true)
              } else {
                commit('setRequestError', true)
                reject(new Error(i18n.t('Problema ao carregar os marcadores')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                commit('setRequestError', true)
                reject(new Error(i18n.t('Problema ao carregar os marcadores')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setRequestError', true)
              reject(new Error(i18n.t('Problema ao carregar os marcadores')))
            }
          })
      })
    },

    // Guarda as alterações da informação dos marcadores
    async saveChangeBookmarks({ state }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        state.form_data.value.forEach(bookmark => {
          let sw168s01 = ''
          if (bookmark.sw168s01) {
            sw168s01 = btoa(bookmark.sw168s01)
          }

          let sw168s04 = ''
          sw168s04 = bookmark.sw168s04

          formData.append('sw168s04[]', sw168s04)
          formData.append(`sw168s01[${sw168s04}]`, sw168s01)
          formData.append(`sw168s03[${sw168s04}]`, bookmark.sw168s03)
          formData.append(`sw168s11[${sw168s04}]`, bookmark.sw168s11)
        })

        api.post(`${apiConfig.url_base_api}salesprocess/saveFormGestBookmarks`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              if (response.data.error === 0) {
                resolve({ error: 0, message: response.data.msg, msg_detail: response.data.msg_detail })
              } else {
                resolve({ error: 1, message: response.data.msg, msg_detail: response.data.msg_detail })
              }
            } else {
              reject(new Error(i18n.t('Problema ao guardar os marcadores')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar os marcadores')))
            }
          })
      })
    },

    // Adiciona novo marcador
    async createNewBookmark({ commit }) {
      return new Promise(resolve => {
        commit('createNewBookmark')
        resolve(true)
      })
    },

  },
}
