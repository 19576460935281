import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({

  // variaveis dos selecionados
  listviewSelectedProcesso: ref(0),
  listviewSelectedSeparador: ref(1),
  listviewSelectedOrigemImovel: ref(''),
  listviewSelectedProcessosMorada: ref(''),
  listviewSelectedMarcadores: ref([]),
  listviewSelectedRating: ref(''),
  listviewSelectedDataTipo: ref(''),
  listviewSelectedIntervaloDataInicio: ref(''),
  listviewSelectedIntervaloDataFim: ref(''),
  listviewSelectedFiltroAvancado: ref([]),
  listviewSelectedBanco: ref([]),
  listviewSelectedEstadoCPCV: ref(''),
  listviewSelectedConsultor: ref(''),
  listviewSelectedConsultorID: ref(''),
  listviewSelectedFields: ref(0),

  listviewFilterSHOWconsultor: ref(0),
  listviewFilterSHOWbancos: ref(0),
  listviewFilterSHOWcpcv: ref(0),

  // variaveis dos filtros
  listviewFilterSeparadores: ref([]),
  listviewFilterOrigemImovel: ref([]),
  listviewFilterProcessosMorada: ref([]),
  listviewFilterMarcadores: ref([]),
  listviewFilterRatings: ref([
    {
      id: '0',
      desc: i18n.t('Sem rating'),
    },
    {
      id: '5',
      desc: i18n.t('Não interessa'),
    },
    {
      id: '7',
      desc: i18n.t('Daqui a 3 semanas'),
    },
    {
      id: '8',
      desc: i18n.t('Daqui a 2 semanas'),
    },
    {
      id: '9',
      desc: i18n.t('Na semana seguinte'),
    },
    {
      id: '10',
      desc: i18n.t('Esta semana'),
    },
  ]),
  listviewFilterDatasTipo: ref([
    {
      id: 'INCcobrado_sw170',
      desc: i18n.t('INC cobrado'),
    },
    {
      id: 'ultimaAlteracao',
      desc: i18n.t('Próximo passo'),
    },
    {
      id: 'sw152s08',
      desc: i18n.t('Criação do processo'),
    },
    {
      id: 'cpcv_sw156s21',
      desc: i18n.t('Prazo de contrato'),
    },
    {
      id: 'escritura_sw156s05',
      desc: i18n.t('Previsão de escritura'),
    },
    {
      id: 'escritura_sw156s06',
      desc: i18n.t('Escritura realizada'),
    },
  ]),
  listviewFilterDatasTipoArrendamento: ref([
    {
      id: 'INCcobrado_sw170',
      desc: i18n.t('INC cobrado'),
    },
    {
      id: 'ultimaAlteracao',
      desc: i18n.t('Próximo passo'),
    },
    {
      id: 'sw152s08',
      desc: i18n.t('Criação do processo'),
    },
    {
      id: 'escritura_sw156s05',
      desc: i18n.t('Previsão de arrendamento'),
    },
    {
      id: 'escritura_sw156s06',
      desc: i18n.t('Arrendamento realizado'),
    },
  ]),
  listviewFilterFiltroAvancado: ref([
    {
      id: 'INC_cobrado',
      desc: i18n.t('INC cobrado'),
    },
    {
      id: 'INC_emfalta',
      desc: i18n.t('INC não cobrado'),
    },
    {
      id: 'consultor',
      desc: i18n.t('Consultor'),
    },
    {
      id: 'com_cpcv',
      desc: i18n.t('Com CPCV'),
    },
    {
      id: 'direto_escritura',
      desc: i18n.t('Direto para escritura'),
    },
    {
      id: 'financiamento_SIGNED',
      desc: i18n.t('Com financiamento ZOME'),
    },
    {
      id: 'financimaneto_cliente',
      desc: i18n.t('Com financiamento cliente'),
    },
    {
      id: 'sem_financiamento',
      desc: i18n.t('Sem financiamento'),
    },
    {
      id: 'distrate_hipoteca',
      desc: i18n.t('Distrate de hipoteca'),
    },
    {
      id: 'INC_descontos',
      desc: i18n.t('Descontos por aprovar'),
    },
    {
      id: 'partilhaexterna',
      desc: i18n.t('Partilha externa'),
    },
    {
      id: 'zomenow',
      desc: i18n.t('ZOME NOW'),
    },
  ]),
  listviewFilterFiltroAvancadoArrendamento: ref([
    {
      id: 'INC_cobrado',
      desc: i18n.t('INC cobrado'),
    },
    {
      id: 'INC_emfalta',
      desc: i18n.t('INC não cobrado'),
    },
    {
      id: 'consultor',
      desc: i18n.t('Consultor'),
    },
    {
      id: 'INC_descontos',
      desc: i18n.t('Descontos por aprovar'),
    },
    {
      id: 'partilhaexterna',
      desc: i18n.t('Partilha externa'),
    },
    {
      id: 'zomenow',
      desc: i18n.t('ZOME NOW'),
    },
  ]),
  listviewFilterEstadoCPCV: ref([
    {
      id: '1',
      desc: i18n.t('Assinado'),
    },
    {
      id: '2',
      desc: i18n.t('Por assinar'),
    },
  ]),
  listviewFilterBancos: ref([]),

  // Variaveis gerais da listagem
  listviewInit: ref(1),
  listviewData: ref([]),
  viewInfoDataSelected: ref({}),
  tabSelectFilter: ref(''),
  loadingListview: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  listviewOrderBy: ref({
    column: '3',
    sort: 'desc',
  }),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listiview_txt_search: i18n.t('ID Angariação, ID Processo ou Consultor'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  aFieldsOrder: [
    {
      name: '1',
      txt: i18n.t('Ordenar pelo ID Angariação'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar pelo ID Processo'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar pelo próximo passo'),
    },
    {
      name: '4',
      txt: i18n.t('Ordenar pelo valor de negócio'),
    },
    {
      name: '5',
      txt: i18n.t('Ordenar pelo valor de comissão contratada'),
    },
    {
      name: '6',
      txt: i18n.t('Ordenar pelo valor de comissão cobrada'),
    },
    {
      name: '7',
      txt: i18n.t('Ordenar pelo rating'),
    },
    {
      name: '8',
      txt: i18n.t('Ordenar pelo angariador'),
    },
    {
      name: '9',
      txt: i18n.t('Ordenar pelo comprador'),
    },
  ],
  txtlabelComissaoRecebida: i18n.t('Comissão recebida'),
  txtlabelComissaoPendente: i18n.t('Comissão pendente'),
  filterSelectedTotalFields: ref(0),
  pageSel: ref(''),

  // States para guardar em localstorage
  historyKeyStorage: '',
  statesToLocalStorage: [
    'listviewSelectedProcesso',
    'listviewSelectedSeparador',
    'listviewSelectedOrigemImovel',
    'listviewSelectedProcessosMorada',
    'listviewSelectedMarcadores',
    'listviewSelectedRating',
    'listviewSelectedDataTipo',
    'listviewSelectedIntervaloDataInicio',
    'listviewSelectedIntervaloDataFim',
    'listviewSelectedFiltroAvancado',
    'listviewSelectedBanco',
    'listviewSelectedEstadoCPCV',
    'listviewSelectedConsultor',
    'listviewSelectedConsultorID',
    'listviewSelectedFields',

    'listviewFilterSHOWconsultor',
    'listviewFilterSHOWbancos',
    'listviewFilterSHOWcpcv',

    'tabSelectFilter',
    'currentPage',
    'searchTxt',
    'listviewOrderBy',
    'positionListview',
  ],
})

export default defaultState
