<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          :class="{'email-app-menu': true}"
        >
          <vue-perfect-scrollbar
            ref="filterScroll"
            class="sidebar-menu-list scroll-area"
            :settings="configSettingsScroll"
          >

            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                {{ $t('Separador') }}
              </h6>
            </div>

            <b-list-group
              v-if="filterData.listviewFilterSeparadores.length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="oTab in filterData.listviewFilterSeparadores.filter((oTab) => oTab.show === true)"
                :key="oTab.key"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.separadorSelecionado === oTab.key) ? true : false)"
                @click.stop.prevent="listviewFilterTab(oTab.key);$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">{{ oTab.text }}</span>
              </b-list-group-item>
            </b-list-group>

            <div
              v-if="filterData.listviewFilterProcessosMorada.length > 0"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Moradas') }}
              </h6>
            </div>

            <v-select
              v-if="filterData.listviewFilterProcessosMorada.length > 0"
              id="fSW152_ImovelMorada"
              v-model="filterSelected.moradaSelecionado"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterData.listviewFilterProcessosMorada"
              class="mb-1 ml-2 mr-2"
              label="desc"
              item-text="desc"
              item-value="id"
              append-to-body
              :calculate-position="withPopper"
              @input="selectFilterOptions(['fSW152_ImovelMorada', $event])"
            >
              <template #option="{ desc }">
                {{ desc }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>

            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                {{ $t('Origem do imóvel') }}
              </h6>
            </div>

            <v-select
              id="fSW152_OrigemImovel"
              v-model="filterSelected.origemImovelSelecionado"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterData.listviewFilterOrigemImovel"
              class="mb-1 ml-2 mr-2"
              label="desc"
              item-text="desc"
              item-value="id"
              append-to-body
              :calculate-position="withPopper"
              @input="selectFilterOptions(['fSW152_OrigemImovel', $event])"
            >
              <template #option="{ desc }">
                {{ desc }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>

            <!-- Marcadores -->
            <div
              v-if="filterData.listviewFilterMarcadores.length > 0"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Marcadores') }}
              </h6>
              <feather-icon
                v-if="$store.getters['auth/currentUser'].id_role != 4 && $store.getters['auth/currentUser'].id_role != 7"
                icon="EditIcon"
                class="cursor-pointer"
                :size="sizeIconsAction"
                @click.stop.prevent="formGestBookmarks();$emit('close-left-sidebar')"
              />
            </div>

            <v-select
              v-if="filterData.listviewFilterMarcadores.length > 0"
              id="fSW152_Bookmarks"
              v-model="filterSelected.marcadoresSelecionados"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterData.listviewFilterMarcadores"
              :value="filterSelected.marcadoresSelecionados"
              class="mb-1 ml-2 mr-2"
              :multiple="true"
              label="sw168s03"
              item-text="sw168s03"
              item-value="sw168s01"
              append-to-body
              :calculate-position="withPopper"
              @input="selectFilterOptions(['fSW152_Bookmarks', $event])"
            >
              <template #option="{ sw168s03, sw168s11 }">
                <feather-icon
                  icon="BookmarkIcon"
                  size="18"
                  class="mr-75"
                  :style="{color: sw168s11}"
                />
                {{ sw168s03 }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>

            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                {{ $t('Rating') }}
              </h6>
            </div>

            <v-select
              id="fSW152_Ratings"
              v-model="filterSelected.ratingSelecionado"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterData.listviewFilterRatings"
              class="mb-1 ml-2 mr-2"
              label="desc"
              item-text="desc"
              item-value="id"
              append-to-body
              :calculate-position="withPopper"
              @input="selectFilterOptions(['fSW152_Ratings', $event])"
            >
              <template #option="{ desc }">
                {{ desc }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>

            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                {{ $t('Filtro de datas') }}
              </h6>
            </div>

            <v-select
              id="fSW152_fltTipoDateSearch"
              v-model="filterSelected.filtroDataSelecionado"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageSel === 'sales-listview-processos-venda' ? filterData.listviewFilterDatasTipo : filterData.listviewFilterDatasTipoArrendamento "
              class="mb-1 ml-2 mr-2"
              label="desc"
              item-text="desc"
              item-value="id"
              append-to-body
              :calculate-position="withPopper"
              @input="selectFilterOptions(['fSW152_fltTipoDateSearch', $event])"
            >
              <template #option="{ desc }">
                {{ desc }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>

            <div
              v-if="filterSelected.filtroDataSelecionado !== null && filterSelected.filtroDataSelecionado !==''"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Intervalo de datas') }}
              </h6>
            </div>

            <b-form-group
              v-if="filterSelected.filtroDataSelecionado !== null && filterSelected.filtroDataSelecionado !==''"
              class="text-left mt-2 ml-2 mr-2"
            >
              <b-input-group>
                <cleave
                  v-model="filterSelected.intervaloDataInicioSelecionado"
                  class="form-control"
                  inputmode="numeric"
                  :raw="false"
                  :options="maskDate"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  readonly
                  @input="selectFilterIntervaloDataInicio($event)"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filterSelected.intervaloDataInicioSelecionado"
                    button-variant="outline-theme h-auto"
                    placeholder="yyyy-mm-dd"
                    autocomplete="off"
                    show-decade-nav
                    hide-header
                    locale="pt"
                    label-help=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    button-only
                    right
                    @input="selectFilterIntervaloDataInicio($event)"
                  />
                </b-input-group-append>
              </b-input-group>
              <h6
                class="section-label text-center mt-1 mb-1"
              >
                {{ $t('a') }}
              </h6>
              <b-input-group>
                <cleave
                  v-model="filterSelected.intervaloDataFimSelecionado"
                  class="form-control"
                  inputmode="numeric"
                  :raw="false"
                  :options="maskDate"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  readonly
                  @input="selectFilterIntervaloDataFim($event)"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filterSelected.intervaloDataFimSelecionado"
                    :min="filterSelected.intervaloDataInicioSelecionado"
                    button-variant="outline-theme h-auto"
                    placeholder="yyyy-mm-dd"
                    autocomplete="off"
                    show-decade-nav
                    hide-header
                    locale="pt"
                    label-help=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    button-only
                    right
                    @input="selectFilterIntervaloDataFim($event)"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                {{ $t('Filtro avançado') }}
              </h6>
            </div>

            <v-select
              id="fSW152_fltAdvanced"
              v-model="filterSelected.filtroAvancadoSelecionado"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageSel === 'sales-listview-processos-venda' ? filterData.listviewFilterFiltroAvancado : filterData.listviewFilterFiltroAvancadoArrendamento "
              class="mb-1 ml-2 mr-2"
              multiple
              label="desc"
              item-text="desc"
              item-value="id"
              append-to-body
              :calculate-position="withPopper"
              @input="selectFilterOptions(['fSW152_fltAdvanced', $event])"
            >
              <template #option="{ desc }">
                {{ desc }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>

            <div
              v-if="filterControl.listviewFilterSHOWconsultor > 0"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Consultor') }}
              </h6>
            </div>

            <validation-provider>
              <b-form-group
                v-if="filterControl.listviewFilterSHOWconsultor > 0"
                class="mb-1 ml-2 mr-2"
              >
                <v-select
                  v-model="filterSelected.consultorSelecionado"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="listConsultoresApp"
                  append-to-body
                  :calculate-position="withPopper"
                  :loading="isLoadingConsultores"
                  :filterable="false"
                  :placeholder="$t('Digite o nome do consultor que procura')"
                  label="desc"
                  item-text="desc"
                  item-value="id"
                  @search="onSearchConsultoresAppSelect"
                  @input="selectFilterOptions(['fSW152_fltConsultor', $event])"
                >
                  <div
                    v-if="listConsultoresApp.length > 0"
                    slot="no-options"
                  >
                    {{ $t('Sem resultados') }}
                  </div>
                  <template
                    v-if="listConsultoresApp.length === 0"
                    slot="no-options"
                  >
                    {{ $t('Digite o nome') }}
                  </template>
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <div class="d-flex align-items-center">
                      <b-avatar
                        :src="option.photo"
                        class="mr-50"
                      />
                      <div class="detail">
                        <b-card-text class="mb-0">
                          {{ option.desc }}
                        </b-card-text>
                        <b-card-text
                          v-if="option.email"
                          class="mb-0 text-muted"
                        >
                          <small>{{ option.email }}</small>
                        </b-card-text>
                        <b-card-text
                          v-if="option.phone"
                          class="mb-0 text-muted"
                        >
                          <small>{{ option.phone }}</small>
                        </b-card-text>
                        <b-card-text
                          v-if="option.tipo"
                          class="mb-0 text-muted"
                        >
                          <small>{{ option.tipo }}</small>
                        </b-card-text>
                      </div>
                    </div>
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ option.desc }}
                  </template>
                </v-select>
              </b-form-group>
            </validation-provider>

            <div
              v-if="filterControl.listviewFilterSHOWcpcv > 0"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Estado CPCV') }}
              </h6>
            </div>

            <v-select
              v-if="filterControl.listviewFilterSHOWcpcv > 0"
              id="fSW152_fltComCPCV"
              v-model="filterSelected.estadoCPCVSelecionado"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterData.listviewFilterEstadoCPCV"
              class="mb-1 ml-2 mr-2"
              label="desc"
              item-text="desc"
              item-value="id"
              append-to-body
              :calculate-position="withPopper"
              @input="selectFilterOptions(['fSW152_fltComCPCV', $event])"
            >
              <template #option="{ desc }">
                {{ desc }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>

            <div
              v-if="filterControl.listviewFilterSHOWbancos > 0"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Bancos') }}
              </h6>
            </div>

            <v-select
              v-if="filterControl.listviewFilterSHOWbancos > 0"
              id="fSW152_fltBancos"
              v-model="filterSelected.bancoSelecionado"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterData.listviewFilterBancos"
              class="mb-1 ml-2 mr-2"
              multiple
              label="sw129s02"
              item-text="sw129s02"
              item-value="sw129s01"
              append-to-body
              :calculate-position="withPopper"
              @input="selectFilterOptions(['fSW152_fltBancos', $event])"
            >
              <template #option="{ sw129s02 }">
                {{ sw129s02 }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>

            <div
              v-if="filterSelectedTotalFields > 0"
              class="form-group-compose text-center compose-btn"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                class="mr-1"
                @click="resetListviewFilter();$emit('close-left-sidebar')"
              >
                <span
                  class="align-middle"
                >
                  {{ $t('Limpar filtros') }}
                </span>
              </b-button>
            </div>

            <h6
              class="section-label mt-3 mb-1 px-2"
            >
              &nbsp;
            </h6>

          </vue-perfect-scrollbar>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import store from '@/store'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import {
  BListGroup, BListGroupItem, BFormDatepicker, BFormGroup, BInputGroup, BInputGroupAppend, BAvatar, BCardText, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mask } from 'vue-the-mask'
import Cleave from 'vue-cleave-component'
import { sizeIconsAction, debounce } from '@core-custom/mixins/geral'
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from './mixins'

export default {
  directives: {
    Ripple,
    mask,
  },
  components: {
    VuePerfectScrollbar,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    BAvatar,
    BCardText,
    vSelect,
    BFormDatepicker,
    Cleave,
    BButton,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    formGestBookmarks: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      maskDate: {
        date: true,
        delimiter: '-',
        datePattern: ['Y', 'm', 'd'],
      },
      search: [],
      listConsultoresApp: [],
      isLoadingConsultores: false,

    }
  },
  computed: {
    ...mapGetters('sales_processos', ['filterData', 'filterSelected', 'filterControl', 'filterSelectedTotalFields', 'pageSel']),
  },
  methods: {
    ...mapActions('sales_processos', ['selectFilterOptions', 'selectFilterIntervaloDataInicio', 'selectFilterIntervaloDataFim']),
    encodeID(id) {
      return btoa(id)
    },
    listviewFilterTab(tab) {
      this.$store.dispatch('sales_processos/listviewFilterTab', tab).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    resetListviewFilter() {
      this.$store.dispatch('sales_processos/resetListviewFilterGeral').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    onSearchConsultoresAppSelect(search, loading) {
      if (search.length) {
        loading(true)
        this.searchConsultoresAppSelect(loading, search, this)
      }
    },
    searchConsultoresAppSelect: debounce((loading, search, vm) => {
      const oThis = vm

      vm.$store.dispatch('sw001/searchConsultoresByLocationMktAuth', search).then(res => {
        oThis.listConsultoresApp = res
        loading(false)
      }).catch(error => {
        vm.showMsgErrorRequest(error)
        oThis.listConsultoresApp = []
        loading(false)
      })
    }, 500),
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu .sidebar-menu-list{
  position: relative;
  height: calc(100%);
}
</style>
