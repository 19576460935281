<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal(false)"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Detalhes do processo') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <vue-perfect-scrollbar
          class="b-sidebar-scrollarea-form"
          :settings="configSettingsScroll"
        >
          <b-container
            class="pt-2 pb-0 pl-2 pr-2"
            fluid
          >
            <b-row>
              <b-col md="12">
                <b-form-group
                  :label="$t('ID Angariação')"
                ><p
                  class="text-primary mb-0"
                >
                  {{ row[1] }}
                  <b-badge
                    v-if="row[16]"
                    variant="light-primary"
                  >
                    {{ $t('Zome Now') }}
                  </b-badge>
                </p>

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('ID Processo')"
                ><p
                  class="text-primary mb-0"
                >
                  {{ row[2] }}
                </p>

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('Próximo passo')"
                ><p
                  class="text-primary mb-0"
                >
                  <span
                    v-b-tooltip
                    :title="row[13] !== '' ? row[13] : null"
                  >{{ row[12] || '----' }}</span>
                </p>

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('Valor de negócio')"
                ><p
                  class="text-primary mb-0"
                >
                  {{ formatPrice(row[7]) }}
                </p>

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('Valor de comissão contratado')"
                ><p
                  class="text-primary mb-0"
                >
                  <span
                    v-if="row[9]"
                  >
                    {{ formatPrice(row[9]) }}
                  </span>
                  <span
                    v-if="row[10]"
                    style="text-decoration: line-through"
                  >
                    {{ row[10] }}
                  </span>
                </p>

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('Valor de comissão cobrada')"
                ><p
                  class="text-primary mb-0"
                >
                  <span>
                    {{ row[11] }}
                  </span>
                </p>

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('Rating')"
                ><p
                  class="text-primary mb-0"
                >
                  <span
                    v-b-tooltip
                    :title="row[15] !== '' ? row[15] : null"
                  >
                    {{ row[14] }}
                  </span>
                </p>

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('Angariador')"
                ><p
                  class="text-primary mb-0"
                >
                  <span
                    v-if="row[3]"
                  >
                    <feather-icon
                      icon="UserIcon"
                    />
                    {{ row[3] }}
                  </span>
                  <br>
                  <span
                    v-if="row[3]"
                  >
                    <feather-icon
                      icon="HomeIcon"
                    />
                    {{ row[4] }}
                  </span>
                </p>

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('Comprador')"
                ><p
                  class="text-primary mb-0"
                >
                  <span
                    v-if="row[5]"
                  >
                    <feather-icon
                      icon="UserIcon"
                    />
                    {{ row[5] }}
                  </span>
                  <br>
                  <span
                    v-if="row[5]"
                  >
                    <feather-icon
                      icon="HomeIcon"
                    />
                    {{ row[6] }}
                  </span>
                </p>

                </b-form-group>
              </b-col>
            </b-row>

          </b-container>

        </vue-perfect-scrollbar>
        <div
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click.stop.prevent="hide"
          >
            {{ $t('Fechar') }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BContainer, BRow, BCol, VBTooltip, BFormGroup, BButton, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { configSettingsScroll, formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BSidebar,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    VuePerfectScrollbar,
    BBadge,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    row: {
      type: Array,
      required: true,
    },
  },
  methods: {
    closeModal(val) {
      this.$parent.showProcessoModalDetail = val
    },
  },
  setup() {
    return {
      configSettingsScroll,
      formatPrice,
    }
  },
}
</script>
