<template>
  <ul
    class="email-media-list"
  >
    <b-media
      v-for="(row) in listviewData"
      :key="row.id"
      tag="li"
      no-body
    >
      <b-media-body>
        <div class="mail-details">
          <b-container
            fluid
          >
            <b-row>
              <b-col md="12">
                <h5
                  v-if="row[1]"
                  class=""
                >
                  {{ row[1] }} - {{ row[2] }}

                  <b-badge
                    v-if="row[16]"
                    variant="light-primary"
                  >
                    {{ $t('Zome Now') }}
                  </b-badge>
                </h5>

              </b-col>
            </b-row>
            <b-row>
              <b-col md="3 mt-1">
                <span
                  v-if="row[3]"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row[3] }}
                </span>
                <br>
                <span
                  v-if="row[3]"
                >
                  <feather-icon
                    icon="HomeIcon"
                  />
                  {{ row[4] }}
                </span>
                <div
                  v-if="row[3]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Angariador') }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="3 mt-1"
              >
                <span
                  v-if="row[5]"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row[5] }}
                </span>
                <br>
                <span
                  v-if="row[5]"
                >
                  <feather-icon
                    icon="HomeIcon"
                  />
                  {{ row[6] }}
                </span>
                <div
                  v-if="row[5]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Comprador') }}
                  </p>
                </div>
              </b-col>
              <b-col md="3 mt-1">
                <span
                  v-if="row[7]"
                >
                  {{ formatPrice(row[7]) }}
                </span>
                <div
                  v-if="row[7]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Valor de negócio') }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="1"
                class="mt-2"
              >
                <!-- Itens de alerta -->
                <div
                  class="mail-message"
                >
                  <p class="mb-0">
                    <feather-icon
                      v-if="row[0] === 1 || row[0] === 2"
                      v-b-tooltip
                      icon="CheckCircleIcon"
                      :title="row[0] === 2 ? `${labelData.txtlabelComissaoPendente}`: (row[0] === 1 ? `${labelData.txtlabelComissaoRecebida}`:null)"
                      :class="row[0] === 2 ? 'text-warning': (row[0] === 1 ? 'text-success':null)"
                      size="21"
                    />
                  </p>
                </div>
              </b-col>
              <b-col
                md="2"
                class="mt-1"
              >
                <div class="align-items-center justify-content-start">
                  <b-button
                    variant="outline-primary"
                    @click.stop.prevent="openModalDetailByRow(row)"
                  >
                    <span class="align-middle">{{ $t('Ver detalhes') }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div class="mail-items" />
        </div>

        <!-- Tipo de cliente -->

        <!-- Itens de alerta -->
      </b-media-body>
    </b-media>
  </ul>
</template>

<script>
import {
  BMedia, BMediaBody, VBTooltip, BRow, BCol, BContainer, BButton, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BMedia,
    BMediaBody,
    BRow,
    BCol,
    BContainer,
    BButton,
    BBadge,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    openModalDetailByRow: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      formatPrice,
    }
  },
  computed: {
    ...mapGetters('sales_processos', ['listviewData', 'filterSelected', 'labelData']),
  },
  methods: {},
}
</script>
