import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import checkNested from 'check-nested'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    pageSel: state => state.pageSel.value,
    listviewInit: state => state.listviewInit.value,
    listviewData: state => state.listviewData.value,
    viewInfoDataSelected: state => state.viewInfoDataSelected.value,
    listviewOrderBy: state => state.listviewOrderBy.value,
    totalRows: state => state.totalRows.value,
    perPage: state => state.perPage.value,
    tabSelectFilter: state => state.tabSelectFilter.value,
    loadingListview: state => state.loadingListview.value,
    currentPage: state => state.currentPage.value,
    searchTxt: state => state.searchTxt.value,
    fieldsOrder: state => state.aFieldsOrder,
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_search: state.listiview_txt_search,
      txt_loading: state.listview_txt_loading,
    }),
    filterSelected: state => ({
      processoSelecionado: state.listviewSelectedProcesso.value,
      separadorSelecionado: state.listviewSelectedSeparador.value,
      origemImovelSelecionado: state.listviewSelectedOrigemImovel.value,
      moradaSelecionado: state.listviewSelectedProcessosMorada.value,
      marcadoresSelecionados: state.listviewSelectedMarcadores.value,
      ratingSelecionado: state.listviewSelectedRating.value,
      filtroDataSelecionado: state.listviewSelectedDataTipo.value,
      intervaloDataInicioSelecionado: state.listviewSelectedIntervaloDataInicio.value,
      intervaloDataFimSelecionado: state.listviewSelectedIntervaloDataFim.value,
      filtroAvancadoSelecionado: state.listviewSelectedFiltroAvancado.value,
      bancoSelecionado: state.listviewSelectedBanco.value,
      estadoCPCVSelecionado: state.listviewSelectedEstadoCPCV.value,
      consultorSelecionado: state.listviewSelectedConsultor.value,
      totalFields: state.listviewSelectedFields.value,
    }),
    filterData: state => ({
      listviewFilterSeparadores: state.listviewFilterSeparadores.value,
      listviewFilterOrigemImovel: state.listviewFilterOrigemImovel.value,
      listviewFilterProcessosMorada: state.listviewFilterProcessosMorada.value,
      listviewFilterMarcadores: state.listviewFilterMarcadores.value,
      listviewFilterRatings: state.listviewFilterRatings.value,
      listviewFilterDatasTipo: state.listviewFilterDatasTipo.value,
      listviewFilterDatasTipoArrendamento: state.listviewFilterDatasTipoArrendamento.value,
      listviewFilterFiltroAvancado: state.listviewFilterFiltroAvancado.value,
      listviewFilterFiltroAvancadoArrendamento: state.listviewFilterFiltroAvancadoArrendamento.value,
      listviewFilterEstadoCPCV: state.listviewFilterEstadoCPCV.value,
      listviewFilterBancos: state.listviewFilterBancos.value,
    }),
    filterControl: state => ({
      listviewFilterSHOWconsultor: state.listviewFilterSHOWconsultor.value,
      listviewFilterSHOWcpcv: state.listviewFilterSHOWcpcv.value,
      listviewFilterSHOWbancos: state.listviewFilterSHOWbancos.value,
    }),
    labelData: state => ({
      txtlabelComissaoRecebida: state.txtlabelComissaoRecebida,
      txtlabelComissaoPendente: state.txtlabelComissaoPendente,
    }),
    filterSelectedTotalFields: state => state.filterSelectedTotalFields.value,
    positionListview: state => state.positionListview,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setFilterData(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setFilterSelected(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setTabSelectFilter(state, payload) {
      state.listviewSelectedSeparador.value = payload
      state.tabSelectFilter.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.listviewOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    addFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value = 0
    },
    resetAllFilterSelected(state) {
      state.filterSelectedTotalFields.value = 0
      state.listviewSelectedRating.value = ''
      state.listviewSelectedProcessosMorada.value = ''
      state.listviewSelectedOrigemImovel.value = ''
      state.listviewSelectedDataTipo.value = ''
      state.listviewSelectedIntervaloDataInicio.value = ''
      state.listviewSelectedIntervaloDataFim.value = ''
      state.listviewFilterSHOWconsultor.value = 0
      state.listviewFilterSHOWcpcv.value = 0
      state.listviewFilterSHOWbancos.value = 0
      state.listviewSelectedFiltroAvancado.value = ''
      state.listviewSelectedEstadoCPCV.value = ''
      state.listviewSelectedBanco.value = ''
      state.listviewSelectedMarcadores.value = ''
      state.listviewSelectedIntervaloDataInicio.value = ''
      state.listviewSelectedIntervaloDataFim.value = ''
      state.listviewSelectedConsultor.value = ''
      state.listviewSelectedConsultorID.value = ''
      state.searchTxt.value = ''
    },
    setfSW152_ImovelMorada(state, payload) {
      if (payload === null) {
        state.listviewSelectedProcessosMorada.value = ''
      } else {
        state.listviewSelectedProcessosMorada.value = payload
      }
    },
    setfSW152_Origemimovel(state, payload) {
      if (payload === null) {
        state.listviewSelectedOrigemImovel.value = ''
      } else {
        state.listviewSelectedOrigemImovel.value = payload
      }
    },
    setfSW152_Ratings(state, payload) {
      if (payload === null) {
        state.listviewSelectedRating.value = ''
      } else {
        state.listviewSelectedRating.value = payload
      }
    },
    setfSW152_fltTipoDateSearch(state, payload) {
      if (payload === null) {
        state.listviewSelectedDataTipo.value = ''
        state.listviewSelectedIntervaloDataInicio.value = ''
        state.listviewSelectedIntervaloDataFim.value = ''
      } else {
        state.listviewSelectedDataTipo.value = payload
      }
    },
    setfSW152_fltAdvanced(state, payload) {
      state.listviewFilterSHOWconsultor.value = 0
      state.listviewFilterSHOWcpcv.value = 0
      state.listviewFilterSHOWbancos.value = 0

      if (payload === null) {
        state.listviewSelectedFiltroAvancado.value = ''
      } else {
        state.listviewSelectedFiltroAvancado.value = payload
        payload.forEach(row => {
          if (row.id === 'consultor') {
            state.listviewFilterSHOWconsultor.value = 1
          }
          if (row.id === 'com_cpcv') {
            state.listviewFilterSHOWcpcv.value = 1
          }
          if (row.id === 'financiamento_SIGNED') {
            state.listviewFilterSHOWbancos.value = 1
          }
        })
      }
    },
    setfSW152_fltComCPCV(state, payload) {
      if (payload === null) {
        state.listviewSelectedEstadoCPCV.value = ''
      } else {
        state.listviewSelectedEstadoCPCV.value = payload
      }
    },
    setfSW152_fltBancos(state, payload) {
      if (payload === null) {
        state.listviewSelectedBanco.value = ''
      } else {
        state.listviewSelectedBanco.value = payload
      }
    },
    setIntervaloDataInicio(state, payload) {
      if (payload === null) {
        state.listviewSelectedIntervaloDataInicio.value = ''
      } else {
        state.listviewSelectedIntervaloDataInicio.value = payload
      }
    },
    setIntervaloDataFim(state, payload) {
      if (payload === null) {
        state.listviewSelectedIntervaloDataFim.value = ''
      } else {
        state.listviewSelectedIntervaloDataFim.value = payload
      }
    },
    setfSW152_Bookmarks(state, payload) {
      if (payload === null) {
        state.listviewSelectedMarcadores.value = ''
      } else {
        state.listviewSelectedMarcadores.value = payload
      }
    },
    setfSW152_fltConsultor(state, payload) {
      if (payload === null) {
        state.listviewSelectedConsultor.value = ''
      } else {
        state.listviewSelectedConsultor.value = payload
      }
    },
    setPageSel(state, payload) {
      state.pageSel.value = payload
    },
    savePositionListview(state, payload) {
      state.positionListview = payload || {}
    },
    setHistoryKeyStorage(state, payload) {
      state.historyKeyStorage = payload || ''
    },
    saveStates(state) {
      if (state.historyKeyStorage !== '') {
        const oStatesHistory = []

        state.statesToLocalStorage.forEach(elm => {
          if (state[elm] !== undefined) {
            oStatesHistory.push({
              key: elm,
              value: state[elm],
            })
          }
        })

        localStorage.setItem(state.historyKeyStorage, JSON.stringify(oStatesHistory))
      }
    },
    initStates(state) {
      if (state.historyKeyStorage !== '') {
        if ((localStorage.getItem(state.historyKeyStorage) !== null) && (localStorage.getItem(state.historyKeyStorage) !== '')) {
          try {
            const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))

            if (stateCustom.length > 0) {
              // Verifica se tem o tabulador selecionado, senao fica perde as preferencias todas
              let bSkipLoadPreferencesListview = false

              stateCustom.forEach(field => {
                if (('key' in field) && ('value' in field)) {
                  if (state.statesToLocalStorage.includes(field.key)) {
                    if (field.key === 'tabSelectFilter') {
                      if (field.value.value === '') {
                        bSkipLoadPreferencesListview = true
                      }
                    }
                  }
                }
              })

              if (bSkipLoadPreferencesListview === false) {
                stateCustom.forEach(field => {
                  if (('key' in field) && ('value' in field)) {
                    if (state.statesToLocalStorage.includes(field.key)) {
                      Object.assign(state[field.key], field.value)
                    }
                  }
                })
              }
            }
          } catch (err) {
            localStorage.removeItem(state.historyKeyStorage)
            //
          }
        }
      }
    },
  },
  actions: {
    // Carregar listagem
    fetchListView({
      state, commit, dispatch,
    }, payload) {
      const listviewFilterParams = new FormData()

      commit('resetFilterSelectedTotalFields')

      if (state.listviewSelectedSeparador.value > 0) {
        listviewFilterParams.append('sw152s13', state.listviewSelectedSeparador.value)
      }

      if (state.listviewSelectedProcessosMorada.value !== '' && state.listviewSelectedProcessosMorada.value !== null) {
        listviewFilterParams.append('fSW152_ImovelMorada', Buffer.from(state.listviewSelectedProcessosMorada.value.id).toString('base64'))
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedOrigemImovel.value !== '' && state.listviewSelectedOrigemImovel.value !== null) {
        listviewFilterParams.append('fSW152_OrigemImovel', Buffer.from(state.listviewSelectedOrigemImovel.value.id).toString('base64'))
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedMarcadores.value.length > 0) {
        state.listviewSelectedMarcadores.value.forEach(val => {
          listviewFilterParams.append('fSW152_Bookmarks[]', Buffer.from(val.sw168s01).toString('base64'))
        })
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedRating.value !== '' && state.listviewSelectedRating.value !== null) {
        listviewFilterParams.append('fSW152_Ratings', Buffer.from(state.listviewSelectedRating.value.id).toString('base64'))
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedDataTipo.value !== '' && state.listviewSelectedDataTipo.value !== null) {
        listviewFilterParams.append('fSW152_fltTipoDateSearch', state.listviewSelectedDataTipo.value.id)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedIntervaloDataInicio.value !== '' && state.listviewSelectedIntervaloDataFim.value !== '') {
        listviewFilterParams.append('fSW152_fltDateInterval', `${state.listviewSelectedIntervaloDataInicio.value} - ${state.listviewSelectedIntervaloDataFim.value}`)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedFiltroAvancado.value.length > 0) {
        state.listviewSelectedFiltroAvancado.value.forEach(val => {
          listviewFilterParams.append('fSW152_fltAdvanced[]', val.id)
        })
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedConsultor.value !== '' && state.listviewSelectedConsultor.value !== null) {
        listviewFilterParams.append('fSW152_fltConsultor', state.listviewSelectedConsultor.value.id)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedEstadoCPCV.value !== '' && state.listviewSelectedEstadoCPCV.value !== null) {
        listviewFilterParams.append('fSW152_fltComCPCV', state.listviewSelectedEstadoCPCV.value.id)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedBanco.value.length > 0) {
        state.listviewSelectedBanco.value.forEach(val => {
          listviewFilterParams.append('fSW152_fltBancos[]', val.sw129s01)
        })
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewOrderBy.value.column !== undefined) {
        listviewFilterParams.append('order[0][column]', state.listviewOrderBy.value.column)
      }

      if (state.listviewOrderBy.value.sort !== undefined) {
        listviewFilterParams.append('order[0][dir]', state.listviewOrderBy.value.sort)
      }

      if (state.searchTxt.value !== '' && state.searchTxt.value !== null) {
        listviewFilterParams.append('search[value]', state.searchTxt.value)
      }

      listviewFilterParams.append('fstartLetter', 'all')
      listviewFilterParams.append('loadContentRecords', 1)
      listviewFilterParams.append('start', ((state.currentPage.value - 1) * state.perPage.value))
      listviewFilterParams.append('length', state.perPage.value)

      // Por defeito, caso nao seja selecionada a pagina, faz sempre reset para a pagina "1"
      if ((payload !== null) && (payload !== undefined) && ('skipCurrentPage' in payload) && (payload.skipCurrentPage === true)) {
        // Fix para nao alterar a pagina que foi selecionada pelo utilizador
      } else if (state.currentPage.value !== 1) {
        commit('setCurrentPage', 1)
      }

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        commit('setListviewInit', 1)
      } else {
        commit('setListviewData', [])
        commit('setTotalRows', 0)
        commit('setLoadingListview', true)
        commit('setListviewInit', 1)
      }

      commit('saveStates')

      let loadListviewCustom

      if (state.pageSel.value === 'sales-listview-processos-venda') {
        loadListviewCustom = 'salesprocess/loadList'
      } else if (state.pageSel.value === 'sales-listview-processos-arrendamento') {
        loadListviewCustom = 'salesprocess/loadLease'
      }

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}${loadListviewCustom}`, listviewFilterParams)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setListviewData', response.data.data)
              commit('setTotalRows', response.data.recordsTotal)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              resolve(true)
            } else {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error('Problema ao carregar a listagem'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    // Obter os filtros da listagem
    async getDataFiltersListview({ state, commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        let pageListviewCustom = ''

        if (payload.pageSel === 'sales-listview-processos-venda') {
          pageListviewCustom = 'salesprocess/list'
        } else if (payload.pageSel === 'sales-listview-processos-arrendamento') {
          pageListviewCustom = 'salesprocess/lease'
        }

        commit('setPageSel', payload.pageSel)

        api.get(`${apiConfig.url_base_api}${pageListviewCustom}`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                if (typeof response.data.data.aOrigemImovel !== 'undefined') {
                  const origemImovel = []

                  Object.entries(response.data.data.aOrigemImovel).forEach(element => {
                    origemImovel.push({ id: element[0], desc: element[1] })
                  })

                  commit('setFilterData', { listviewFilterOrigemImovel: origemImovel })
                }

                if (typeof response.data.data.aMoradas !== 'undefined') {
                  const moradasProcessos = []

                  Object.entries(response.data.data.aMoradas).forEach(element => {
                    moradasProcessos.push({ id: element[0], desc: element[1] })
                  })

                  commit('setFilterData', { listviewFilterProcessosMorada: moradasProcessos })
                }

                commit('setFilterData', { listviewFilterMarcadores: response.data.data.aBookmarksHUB })
                commit('setFilterData', { listviewFilterBancos: response.data.data.fSW152_fltBancos_list })
              }

              // Obter os separadores disponiveis para o utilizador autenticado
              const countryApp = rootGetters['auth/countryApp']

              if (state.listviewFilterSeparadores.value.length === 0) {
                const atabsListview = []
                atabsListview.push({
                  key: 1,
                  text: i18n.t('Novos processos'),
                  show: true,
                })

                atabsListview.push({
                  key: 2,
                  text: i18n.t('Em curso'),
                  show: true,
                })

                atabsListview.push({
                  key: 3,
                  text: i18n.t('Cancelados'),
                  show: true,
                })

                if (countryApp === 'pt') {
                  atabsListview.push({
                    key: 6,
                    text: i18n.t('Pré-concluídos'),
                    show: true,
                  })
                }

                atabsListview.push({
                  key: 4,
                  text: i18n.t('Concluídos'),
                  show: true,
                })

                atabsListview.push({
                  key: 5,
                  text: i18n.t('Em contencioso'),
                  show: true,
                })

                commit('setFilterData', { listviewFilterSeparadores: atabsListview })
              }
              resolve(true)
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o filtro')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o filtro')))
            }
          })
      })
    },

    // Carrega os filtros conforme o separador ativo
    async showFiltersListview({ state, commit, dispatch }) {
      if (state.filterDataTabsListview.value.length > 0) {
        const oTabsListviewActive = state.filterDataTabsListview.value.filter(oTab => oTab.show === true)

        try {
          if (oTabsListviewActive[0].key) {
            commit('setTabSelectFilter', oTabsListviewActive[0].key)
            dispatch('showFiltersListviewByTabSelected')
          }
        } catch {
        //
        }
      }
    },

    showFiltersListviewByTabSelected({ commit }) {
      commit('setShowFiltersListviewByTabSelected')
      commit('setFilterData', { filterDataLoad: true })
    },

    // Ordenação da listagem
    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBy.value.column) && (state.listviewOrderBy.value.column === field)) {
        if (state.listviewOrderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      return dispatch('fetchListView')
    },

    setLeadIDSelected({ commit }, idLead) {
      commit('setSelectedLeadsID', idLead)
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView', { skipCurrentPage: true })
    },

    pullToRefreshListview({ commit, dispatch }) {
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    // Metodos do filtro lateral - Escolher responsavel
    listviewFilterUserResponsable({ commit, dispatch }, oResponsableSelected) {
      if ((oResponsableSelected !== null) && (!!oResponsableSelected.id)) {
        let oResponsable = {}
        oResponsable = oResponsableSelected
        oResponsable.id = btoa(oResponsableSelected.id)

        commit('setUserResponsable', oResponsable)
      } else {
        commit('setUserResponsable', oResponsableSelected)
      }

      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Alterar separador
    listviewFilterTab({ commit, dispatch }, tab) {
      commit('setTabSelectFilter', tab)
      // commit('resetAllFilterSelected')
      // commit('setShowFiltersListviewByTabSelected')
      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Partilhados / Referenciados
    listviewFilterTabField({ commit, dispatch }, tab) {
      if (tab === 'leads_partilhadas') {
        commit('setFilterSelected', { filterSelectedPartilhados: 'all' })
        commit('setFilterSelected', { filterSelectedReferenciados: '' })
      }
      if (tab === 'leads_referenciados') {
        commit('setFilterSelected', { filterSelectedPartilhados: '' })
        commit('setFilterSelected', { filterSelectedReferenciados: 'all' })
      }
      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Filtro geral dos blocos
    listviewFilterGeral({ state, commit, dispatch }, params) {
      try {
        const nameParam = Object.keys(params)[0]
        const valueParam = params[nameParam]

        const indexItem = state[nameParam].value.indexOf(valueParam)
        if (indexItem === -1) {
          state[nameParam].value.push(valueParam)
        } else {
          state[nameParam].value.splice(indexItem, 1)
        }

        if (state[nameParam].value.includes('all')) {
          const indexItemAll = state[nameParam].value.indexOf('all')
          state[nameParam].value.splice(indexItemAll, 1)
        }

        if (state[nameParam].value.length === 0) {
          state[nameParam].value.push('all')
        }

        if (valueParam === 'all') {
          state[nameParam].value = []
          state[nameParam].value.push('all')
        }

        if (nameParam === 'filterSelectedTiposClienteLead') {
          if (state[nameParam].value.includes('2') || state[nameParam].value.includes('4')) {
            state.filterSelectedShowStatusComprador.value = true
          } else {
            state.filterSelectedShowStatusComprador.value = false
            state.filterSelectedEstadosLeadComprador.value = []
            state.filterSelectedEstadosLeadComprador.value.push('all')
          }

          if (state[nameParam].value.includes('1') || state[nameParam].value.includes('3')) {
            state.filterSelectedShowStatusVendedor.value = true
          } else {
            state.filterSelectedShowStatusVendedor.value = false
            state.filterSelectedEstadosLeadVendedor.value = []
            state.filterSelectedEstadosLeadVendedor.value.push('all')
          }
        }

        if (nameParam === 'filterSelectedBookmarksByUser') {
          if (state.filterSelectedBookmarksByUser.value.length < 2) {
            commit('setBookmarksCombinationByUser', false)
          }
        }

        return dispatch('fetchListView')
      } catch {
        return null
      }
    },

    // Limpar todos os filtros selecionados
    resetListviewFilterGeral({ commit, dispatch }) {
      commit('resetAllFilterSelected')
      return dispatch('fetchListView')
    },

    // Atualiza listagem dos marcadores da listagem
    async updateListviewBookmarks({ commit }) {
      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}salesprocess/list`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                commit('setFilterData', { listviewFilterMarcadores: response.data.data.aBookmarksHUB })
              }

              resolve(true)
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao atualizar os marcadores')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao atualizar os marcadores')))
            }
          })
      })
    },

    selectFilterOptions({ commit, dispatch }, params) {
      if (params[0] === 'fSW152_ImovelMorada') {
        commit('setfSW152_ImovelMorada', params[1])
      } else if (params[0] === 'fSW152_OrigemImovel') {
        commit('setfSW152_Origemimovel', params[1])
      } else if (params[0] === 'fSW152_Ratings') {
        commit('setfSW152_Ratings', params[1])
      } else if (params[0] === 'fSW152_fltTipoDateSearch') {
        commit('setfSW152_fltTipoDateSearch', params[1])
      } else if (params[0] === 'fSW152_fltAdvanced') {
        commit('setfSW152_fltAdvanced', params[1])
      } else if (params[0] === 'fSW152_fltComCPCV') {
        commit('setfSW152_fltComCPCV', params[1])
      } else if (params[0] === 'fSW152_fltBancos') {
        commit('setfSW152_fltBancos', params[1])
      } else if (params[0] === 'fSW152_Bookmarks') {
        commit('setfSW152_Bookmarks', params[1])
      } else if (params[0] === 'fSW152_fltConsultor') {
        commit('setfSW152_fltConsultor', params[1])
      }

      return dispatch('fetchListView')
    },

    selectFilterIntervaloDataInicio({ commit, dispatch }, value) {
      commit('setIntervaloDataInicio', value)
      dispatch('fetchListView')
    },

    selectFilterIntervaloDataFim({ commit, dispatch }, value) {
      commit('setIntervaloDataFim', value)
      dispatch('fetchListView')
    },

    getInfoProcess(state, payload) {
      const oParamsFilter = new FormData()
      oParamsFilter.append('sw152s02', payload.sw152s02 || '')

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}salesprocess/loadList`, oParamsFilter)
          .then(response => {
            try {
              if (checkNested(response, 'data.data')) {
                const infoProcess = response.data.data.shift()
                if (infoProcess !== undefined) {
                  resolve(infoProcess)
                } else {
                  reject(new Error(i18n.t('Problema ao obter a informação do processo')))
                }
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a informação do processo')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a informação do processo')))
            }
          })
      })
    },

  },
}
