<template>
  <div>
    <b-modal
      :title="$t('Gestão de marcadores')"
      :cancel-title="$t('Fechar')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="showModal"
      :ok-only="(((requestError=== true) || (form_data.length === 0)) ? true : false)"
      :ok-title="(((requestError=== true) || (form_data.length === 0)) ? $t('Fechar') : 'Guardar')"
      :ok-variant="(((requestError=== true) || (form_data.length === 0)) ? 'outline-secondary' : 'primary')"
      no-close-on-esc
      no-close-on-backdrop
      @ok="saveChangeBookmarks"
      @hidden="hiddenModal"
    >
      <b-row>
        <!-- Mostra mensagem de aviso-->
        <b-col cols="12">
          <b-alert
            v-if="messageAlertForm"
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              {{ $t('Atenção') }}
            </h4>
            <div class="alert-body">
              <span>{{ messageAlertForm }}</span>
            </div>
          </b-alert>
        </b-col>

        <!-- Marcadores -->
        <b-col md="12">
          <b-alert
            show
            variant="warning"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertTriangleIcon"
              />
              <span class="ml-25">{{ $t('As alterações terão efeito para todos os utilizadores do seu HUB.') }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="form_data.length > 0"
          cols="12"
        >
          <validation-observer
            ref="formBookmarksManager"
            #default="{}"
          >
            <draggable
              v-model="form_data"
              :class="{['cursor-grabbing']: dragging === true}"
              tag="app-collapse"
              handle=".handle"
              :component-data="collapseComponentData"
              @start="dragging = true; collapsedAllBookmark()"
              @end="dragging = false"
              @change="collapsedAllBookmark"
            >
              <app-collapse-item
                v-for="(bookmark, index) in form_data"
                :key="`bookmark-item-drag-${index}`"
                ref="bookmarkCollapseItem"
                title=""
              >
                <template #header>
                  <div class="row collapse-title d-inline w-100 text-truncate">
                    <div
                      class="d-inline  mr-75 ml-75 handle"
                    >
                      <feather-icon
                        icon="MenuIcon"
                        :size="sizeIconsAction"
                        :title="$t('Mover marcador')"
                      />
                    </div>
                    <div
                      class="d-inline mr-75 ml-75"
                    >
                      <feather-icon
                        v-b-tooltip
                        icon="BookmarkIcon"
                        :size="sizeIconsAction"
                        :title="`${bookmark.sw168s03}`"
                        :style="{color: bookmark.sw168s11}"
                      />
                    </div>
                    <div
                      class="d-inline mr-75 ml-75"
                    >
                      {{ bookmark.sw168s03 }}
                    </div>
                  </div>
                </template>

                <b-row>
                  <b-col
                    cols="12"
                    style="display:none"
                  >
                    <b-form-group
                      :label="$t('Cor')"
                      :label-for="`bookmark-color-${index}`"
                      label-cols-md="2"
                      class="text-md-right text-sm-left pd-75"
                    >
                      <validation-provider
                        #default="{ errors }"
                        ref="providerColor"
                        rules="required"
                        mode="eager"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-input-group-prepend is-text>
                            <color-picker
                              ref="colorpicker"
                              :value="bookmark.sw168s11"
                              type="chrome"
                              @click.stop.prevent="openFromPicker"
                              @change="updateFromPicker($event, index)"
                            />
                          </b-input-group-prepend>
                          <b-form-input
                            :id="`bookmark-color-${index}`"
                            v-model="bookmark.sw168s11"
                            v-mask="tokenMaskHexColor"
                            placeholder=""
                            :class="errors.length > 0 ? 'is-invalid':null"
                            @blur="$refs.providerColor[index].validate()"
                          />
                        </b-input-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Nome')"
                      :label-for="`bookmark-name-${index}`"
                      label-cols-md="2"
                      class="text-md-right text-sm-left pd-75"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules=""
                      >
                        <b-form-input
                          :id="`bookmark-name-${index}`"
                          v-model="bookmark.sw168s03"
                          placeholder=""
                          :class="errors.length > 0 ? 'is-invalid':null"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </draggable>
          </validation-observer>
        </b-col>

        <!-- Bloco sem registos -->
        <b-col
          v-if="form_data.length === 0"
          cols="12"
        >
          <b-card
            no-body
            class="shadow-none mb-0"
          >
            <b-card-body>
              <b-card-text>{{ $t('Nenhum marcador disponível') }}</b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  BModal, VBModal, VBTooltip, BFormInput, BAlert, BRow, BCol, BFormGroup, BInputGroup, BInputGroupPrepend, BCard, BCardBody, BCardText,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'
import { Dialog } from '@capacitor/dialog'
import { Keyboard } from '@capacitor/keyboard'
import { onUnmounted } from '@vue/composition-api'
import bookmarksModule from '@store-modules/sales/bookmarks'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { greaterThanZero } from '@validations-custom'
import Ripple from 'vue-ripple-directive'
import { VueColorpicker } from 'vue-colorpicker-pop'
import { mask } from 'vue-the-mask'
import Vue from 'vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'

Vue.component('app-collapse', AppCollapse)

export default {
  components: {
    BModal,
    draggable,
    BFormInput,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    AppCollapseItem,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    'color-picker': VueColorpicker,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
    mask,
  },
  mixins: [sizeIconsAction],
  props: {
  },
  data() {
    return {
      greaterThanZero,
      dragging: false,
      showModal: false,
      collapseComponentData: {
        props: {
          accordion: true,
        },
      },
      tokenMaskHexColor: {
        mask: '#XXXXXX',
        tokens: {
          X: {
            pattern: /[0-9a-zA-Z]/,
            transform(v) {
              return v.toLocaleUpperCase()
            },
          },
        },
      },
      tokenMaskNumber: {
        mask: '######',
        tokens: {
          '#': {
            pattern: /\d/,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('bookmarks', ['bookmarks', 'colors', 'permsColors', 'messageAlertForm', 'form_data', 'requestError']),
    ...mapActions('sales_processos', ['updateListviewBookmarks']),
    form_data: {
      get() {
        return this.$store.getters['bookmarks/form_data']
      },
      set(value) {
        this.$store.commit('bookmarks/setUpdateFormData', value)
      },
    },
  },
  async created() {
    try {
      await store.dispatch('bookmarks/getAllBookmarksEdition').catch(error => {
        this.showMsgErrorRequest(error)
      })
      this.showModal = true
    } catch (err) {
      //
    }
  },
  methods: {
    async showMsgErrorRequest(info) {
      if (info.message) {
        if (store.state.capacitor.isNative === true) {
          Dialog.alert({
            title: info.title || this.$t('Alerta'),
            message: info.message,
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: info.title || this.$t('Alerta'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: info.message,
            },
          })
        }
      }
    },
    async showMsgSuccessRequest(info) {
      if (info.message) {
        if (store.state.capacitor.isNative === true) {
          Dialog.alert({
            message: info.message,
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: info.message,
            },
          })
        }
      }
    },
    hiddenModal() {
      this.$parent.showBookmarksModalEdition = false
    },
    setValueCargoByBookmark(selectedValues, index) {
      this.form_data[index].sw104s02 = selectedValues
      this.$store.commit('bookmarks/setUpdateFormData', this.form_data)
    },
    openFromPicker() {
      if (store.state.capacitor.isNative === true) {
        Keyboard.hide()
      }
    },
    updateFromPicker(color, index) {
      let colorHex = ''
      if (color !== '') {
        const rgb2hex = c => `#${c.match(/\d+/g).map(x => (+x).toString(16).padStart(2, 0)).join``}`
        colorHex = rgb2hex(color)
      }

      this.form_data[index].sw168s04 = colorHex
    },
    collapsedAllBookmark() {
      if (this.$refs.bookmarkCollapseItem !== undefined) {
        this.$refs.bookmarkCollapseItem.forEach(elm => {
          elm.updateVisible(false)
        })
      }
    },
    clearSearchCargoByBookmark(index) {
      this.$refs[`bookmarkCargos${index}`][0].search = ''
    },
    async saveChangeBookmarks(e) {
      if (this.requestError === false) {
        e.preventDefault()
        const self = this

        await this.$refs.formBookmarksManager.validate().then(success => {
          if (success) {
            self.$root.$emit('app::loading', true)
            store.dispatch('bookmarks/saveChangeBookmarks').then(res => {
              self.$root.$emit('app::loading', false)

              // Caso tenha informação detalhada
              if (res.error === 0) {
                this.showMsgSuccessRequest({ message: res.message })
              } else {
                this.showMsgErrorRequest({ message: res.message })
              }

              self.hiddenModal()

              // Atualiza os marcadores na listagem
              if (res.error === 0) {
                store.dispatch('sales_processos/updateListviewBookmarks').catch(error => {
                  this.showMsgErrorRequest(error)
                })
              }
            }).catch(error => {
              self.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            }).catch(error => {
              this.showMsgErrorRequest(error)
            })
          } else {
            this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
          }
        })
      }
    },
  },
  setup() {
    const BOOKMARKS_MODULE_NAME = 'bookmarks'

    if (!store.hasModule(BOOKMARKS_MODULE_NAME)) {
      store.registerModule(BOOKMARKS_MODULE_NAME, bookmarksModule)

      onUnmounted(() => {
        if (store.hasModule(BOOKMARKS_MODULE_NAME)) store.unregisterModule(BOOKMARKS_MODULE_NAME)
      })
    }

    return {
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '@core/scss/base/core/mixins/main-menu-mixin';
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/include';

.limitHeightSelect ::v-deep {
  .vs__dropdown-menu{
    max-height: 175px!important;
  }
}

.cursor-grabbing ::v-deep{
  .card{
    .card-header{
      cursor: -webkit-grabbing!important;
      cursor: grabbing!important;
    }
  }
}

.vue-colorpicker{
  padding:0!important;
  border:0!important;
  border-radius: 0!important;
  height: inherit !important;
}

.vue-colorpicker ::v-deep{
  .vue-colorpicker-btn{
    border:1px solid #d8d6de!important;
  }
}

@include media-breakpoint-down(xs) {
  .v-select ::v-deep{
    .vs__selected{
      width: 100%!important;
    }
  }
}
</style>
